
















































import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { DefaBaseButton, DefaBaseInput } from "@defa-as/components";
import { routes } from "@/router";
import { ROUTE_NAMES } from "@/router/route-names";
import { requestPasswordReset } from "@/http/requests/requests-user";
import { findRouteByName } from "@defa-as/utils";

@Component({
  components: {
    DefaBaseInput,
    DefaBaseButton,
    ValidationObserver,
  },
})
export default class ViewPasswordRecovery extends Vue {
  email = "";
  emailSent = false;
  sendingRequest = false;

  get loginLink() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  get recoverPasswordConfirmPath() {
    return findRouteByName(routes, ROUTE_NAMES.RECOVER_PASSWORD.CONFIRM).path;
  }

  async recover() {
    this.sendingRequest = true;
    try {
      await requestPasswordReset({
        email: this.email,
        recoverPasswordConfirmPath: this.recoverPasswordConfirmPath,
      });
      this.emailSent = true;
    } finally {
      this.sendingRequest = false;
    }
  }
}
